import React, { useRef, useState, useEffect } from 'react';
import { Container, Typography, Box, CircularProgress, Paper } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RegistrationForm from './RegistrationForm'; // Import your RegistrationForm component
import Footer from './Footer'; // Import your Footer component
import './App.css';

function App() {
  const [loading, setLoading] = useState(false);
  const [htmlResponse, setHtmlResponse] = useState('');
  const [isSuccess, setIsSuccess] = useState(false); // New state to track success status
  const formRef = useRef();

  useEffect(() => {
    // Check if the URL has status=success
    const params = new URLSearchParams(window.location.search);
    if (params.get('status') === 'success') {
      setIsSuccess(true);
    }
  }, []);

  const sendPaymentData = (email, fullName, invoice, amount, token) => {
    let actionUrl = 'https://webpay.agilpay.net/Payment?culture=en';

    // Create a new form element
    const form = document.createElement('form');
    form.action = actionUrl;
    form.method = 'POST';

    // Add the form data as hidden input fields
    const formData = {
      SiteID: '8uoNZQZj3BjcKy6ZZMY1',
      UserID: email,
      Names: fullName,
      Email: email,
      Identification: email,
      ReturnURL: 'https://pagos-hello-media.web.app/',
      SuccessURL: 'https://pagos-hello-media.web.app/?status=success',
      Detail: `{"Payments":[{"Items":[{"Description":"${invoice}","Quantity":"1","Amount":${amount},"Tax":0}],"MerchantKey":"JRFr6BzgdkzzFEFVn5Ay","Service":"${invoice}","MerchantName":"HELLO MEDIA LLC","Description":"${invoice}","Amount":${amount},"Tax":0,"Currency":"840"}]}`,
      NoHeader: '0',
      BodyBackground: '#f8f9fa',
      PrimaryColor: '#269A9A',
      token: token
    };

    // Create input elements for each form data field and append them to the form
    Object.keys(formData).forEach(key => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = formData[key];
      form.appendChild(input);
    });

    // Append the form to the body to be able to submit it
    document.body.appendChild(form);

    // Submit the form
    form.submit();
  };

  return (
    <div>
      {!isSuccess && !htmlResponse && (
        <Container maxWidth="sm" style={{ marginTop: '20px' }}>
          {/* Logo Section */}
          <Box display="flex" justifyContent="center" mb={4}>
            <img
              src="https://static.wixstatic.com/media/fa54d7_0466063b0f8f4c5f8af9d38cc3f8aef2~mv2.png/v1/fill/w_192,h_122,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/fa54d7_0466063b0f8f4c5f8af9d38cc3f8aef2~mv2.png"
              alt="Logo"
              style={{ maxWidth: '150px', height: 'auto' }}
            />
          </Box>

          {/* Paper Wrapper for Form */}
          <Typography variant="h4" component="h1" align="center" gutterBottom fontWeight={800}>
            Payment Form
          </Typography>

          <RegistrationForm sendPaymentData={sendPaymentData} />

          {/* Optional Loading Indicator */}
          {loading && (
            <Box mt={4} display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
        </Container>
      )}

      {/* Success Message */}
      {isSuccess && (
        <Container maxWidth="sm" style={{ paddingTop: 80, height: '70vh' }} display="flex" justifyContent="center">
          <Box display="flex" justifyContent="center" mb={4}>
            <CheckCircleIcon sx={{ fontSize: 128, color: 'green' }} />
          </Box>
          <Typography variant="h4" component="h1" align="center" gutterBottom fontWeight={800}>
            Thank You!
          </Typography>
          <Typography variant="body1" align="center">
            Your payment was successful. We have received your transaction.
          </Typography>
        </Container>
      )}

      {htmlResponse && <div dangerouslySetInnerHTML={{ __html: htmlResponse }} />}

      {/* Add the Footer component here */}
      <Footer />
    </div>
  );
}

export default App;
