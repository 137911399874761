import React, { useState, useRef } from 'react';
import {
  Box, TextField, Grid, Typography, Stepper, Step, StepLabel, Button, CircularProgress, Container, Paper, InputAdornment,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // For success icon

const RegistrationForm = ({ sendPaymentData }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    company: '',
    email: '',
    note: '',
    amount: '',
  });
  const [success, setSuccess] = useState(false);

  // Use a ref for the form element
  const formRef = useRef(null);

  const steps = ['Your Information', 'Payment Details', 'Confirmation'];

  const handleNext = () => {
    if (activeStep === 1) {
      handleSubmit(); // Trigger form submission on final step
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Helper function to format the integer part of the number
  const formatAmount = (value) => {
    // Split the value into integer and decimal parts
    const [integerPart, decimalPart] = value.split('.');

    // Format the integer part with commas
    const formattedInteger = new Intl.NumberFormat().format(integerPart);

    // Return the formatted value with the decimal part if it exists
    return decimalPart !== undefined ? `${formattedInteger}.${decimalPart}` : formattedInteger;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'amount') {
      // Only allow numbers and one decimal point
      const rawValue = value.replace(/[^0-9.]/g, '');

      // Prevent more than one decimal point
      const decimalMatch = rawValue.match(/\./g);
      if (decimalMatch && decimalMatch.length > 1) return;

      // Format the value while preserving decimals
      const formattedValue = formatAmount(rawValue);

      setFormData({ ...formData, amount: formattedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    if (e) e.preventDefault(); // Prevent default form submission behavior if necessary

    setLoading(true);

    const fullName = `${formData.first_name} ${formData.last_name}`;
    const { company, note, email, amount } = formData;
    const invoice = `${company} - ${note}`;

    // Convert formatted amount back to float
    const rawAmount = parseFloat(amount.replace(/,/g, ''));

    const data = {
      names: fullName,
      email: email,
      company: company,
      identification: email,
      invoice: invoice,
      amount: rawAmount,
    };

    // Call the backend payment processing function
    fetch('https://us-central1-hellovip-f1a1c.cloudfunctions.net/processPayment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        // Assuming the server returns an access_token for confirmation
        sendPaymentData(email, fullName, invoice, rawAmount, data.access_token);
        setLoading(false);
        // setSuccess(true); // Update state to show success screen
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error:', error);
      });
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {!success ? (
          <form ref={formRef} onSubmit={handleSubmit}> {/* Use the ref for form */}
            {activeStep === 0 && (
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="First Name"
                      name="first_name"
                      variant="outlined"
                      required
                      value={formData.first_name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      name="last_name"
                      variant="outlined"
                      required
                      value={formData.last_name}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Box marginY={2}>
                  <TextField
                    fullWidth
                    label="Company Name"
                    name="company"
                    variant="outlined"
                    required
                    value={formData.company}
                    onChange={handleChange}
                  />
                </Box>
                <Box marginY={2}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    variant="outlined"
                    type="email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Box>
              </Box>
            )}

            {activeStep === 1 && (
              <Box>
                <Box marginY={2}>
                  <TextField
                    fullWidth
                    label="Note"
                    name="note"
                    variant="outlined"
                    placeholder='Invoice number, campaign name, etc...'
                    multiline
                    rows={4}
                    value={formData.note}
                    required
                    onChange={handleChange}
                  />
                </Box>
                <Box marginY={2}>
                  <TextField
                    fullWidth
                    label="Amount"
                    name="amount"
                    variant="outlined"
                    type="text" // Changed to text to allow formatting
                    placeholder='E.g. 100.75'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    required
                    value={formData.amount}
                    onChange={handleChange}
                  />
                </Box>
              </Box>
            )}

            <Box display="flex" justifyContent="space-between" marginTop={4}>
              {activeStep > 0 && (
                <Button variant="outlined" onClick={handleBack} disabled={loading}>
                  Back
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={loading || !formData.first_name || !formData.last_name || !formData.company || !formData.email || (activeStep === 1 && (!formData.note || !formData.amount))}
              >
                {loading ? <CircularProgress size={24} /> : activeStep === 1 ? 'Proceed to Payment' : 'Next'}
              </Button>
            </Box>
          </form>
        ) : (
          <Box textAlign="center">
            <CheckCircleIcon color="success" sx={{ fontSize: 60 }} />
            <Typography variant="h6" gutterBottom>
              Payment successfully processed!
            </Typography>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default RegistrationForm;
