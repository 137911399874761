import React from 'react';
import { Box, Grid, Typography, Link, Container } from '@mui/material';

const Footer = () => {
  return (
    <Box 
      sx={{ 
        backgroundColor: '#43b7ae', 
        py: 4,
        mt: 4 
      }}
      textAlign="center"
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* About Us Section */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
              About Us
            </Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
            The #1 Billboard company in coverage in Puerto Rico, with more than 130 billboards and 40 static displays. We are your one-stop shop for all your outdoor advertising needs.
            </Typography>
          </Grid>

          {/* Contact Section */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
              Contact Us
            </Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
              787-668-0000
            </Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
              info@hellomediapr.com
            </Typography>
          </Grid>

          {/* Social Media Section */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
              Follow Us
            </Typography>
            <ul style={{ padding: 0, listStyle: 'none' }}>
              <li><Link href="https://www.facebook.com/HelloMediaPR/" color="inherit" sx={{ color: 'white' }}>Facebook</Link></li>
              <li><Link href="https://www.instagram.com/Hello_MediaPR/" color="inherit" sx={{ color: 'white' }}>Instagram</Link></li>
              
            </ul>
          </Grid>
        </Grid>

        <Box textAlign="center" mt={4}>
          <Typography variant="body2" sx={{ color: 'white' }}>
            © 2024 Hello Ventas, Inc. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
